// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-reader-js": () => import("./../../../src/pages/projects/reader.js" /* webpackChunkName: "component---src-pages-projects-reader-js" */),
  "component---src-pages-projects-steadfast-js": () => import("./../../../src/pages/projects/steadfast.js" /* webpackChunkName: "component---src-pages-projects-steadfast-js" */),
  "component---src-pages-projects-veritable-news-js": () => import("./../../../src/pages/projects/veritable-news.js" /* webpackChunkName: "component---src-pages-projects-veritable-news-js" */),
  "component---src-pages-projects-youtube-8-m-2019-js": () => import("./../../../src/pages/projects/youtube8m-2019.js" /* webpackChunkName: "component---src-pages-projects-youtube-8-m-2019-js" */)
}

